const getRootUrl = (stage) => {
    if (stage === "dev") return "https://telehealth-api-dev.netcaretech.co.za";
    else if (stage === "prod") return "https://telehealth-api-prod.netcaretech.co.za";

    // Default
    return "http://localhost:8888";
};

const getVCSlotBookingRootUrl = (stage) => {
    if (stage === "dev") return "https://virtualcare-v2-api-dev.netcare.digital";
    // else if (stage === "staging") return "virtualcare-v2-api-staging.netcare.digital";
    else if (stage === "prod") return "https://virtualcare-v2-api.netcare.digital";

    // Default
    return "http://localhost:8000";
};

const getVideoUrl = (stage) => {
    if (stage === "dev") return "https://video-dev.netcaretech.co.za";
    else if (stage === "prod") return "https://video.netcaretech.co.za";

    // Default
    return "http://localhost:8888";
};

export const STAGE = process.env.REACT_APP_STAGE;
export const API_ROOT_URL = getRootUrl(STAGE);
export const API_VCSLOTBOOKING_URL = getVCSlotBookingRootUrl(STAGE);
export const AUTHENTICATION_URL = "https://auth.netcaretech.co.za";
export const AUTHENTICATION_CLIENT_ID = "0fCnazhXmlap7tPjzG6v6AXffBFdyZuz";
export const AUTHENTICATION_AUDIENCE = "https://services.netcare.co.za";
export const AUTHENTICATION_CONNECTION = "Username-Password-Authentication";
export const VIDEO_URL = getVideoUrl(STAGE);
export const GROUP_VIDEO_URL = getVideoUrl(STAGE).replace('video', 'consult');